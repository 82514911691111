<template>
<div class="fullscreen">
    
    <Spacer />
    <Box>
      <ErrorRow :error="error" />
      <ProgressRow :display="isRemoving" />
      <SuccessRow :message="message" />
    </Box>
    

    <Box>
      <Row>
        <Column :width="4" />
        <Column :width="7">
          <Box>
            <TitleRow :left="4" :right="4"> {{ MC.Vehicle.Remove.Title.value() }} </TitleRow>
          </Box>
        </Column>
        <Column :width="4" />
      </Row>

      <Row>
        <Column :width="4" />
        <Column :width="7" :showBorder="true">
            <Label align="centre" fontSize="xxxlarge">{{ MC.Vehicle.Confirm.value() }}</Label>
            <Value align="centre" fontSize="large">
             {{ MC.Vehicle.Remove.Message.value([vehicleObj().name(), vehicleObj().plate()]) }}
            </Value>
        </Column>
        <Column :width="4" />
      </Row>
    
      <Row>
        <Column :width="4" />
          <Column :width="7" >
            <Button v-on:click="confirm" :go="true"
              :disabled="isRemoving">{{ MC.Vehicle.Remove.Remove.value() }} </Button>
          </Column>
        <Column :width="4" />  
      </Row>  

      <Row>
        <Column :width="4" />
        <Column :width="7" >
          <Button v-on:click="reject" 
            :disabled="isRemoving">
            {{ MC.Vehicle.Back.value() }}
          </Button>
        </Column>
        <Column :width="4" />  
      </Row>
      
    </Box>
  
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils  from '@/utils/StringUtils.js';
import ErrorRow    from '@/components/row/ErrorRow.vue';
import SuccessRow  from "@/components/row/SuccessRow.vue";

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import Button from "@/portals/shared/library/button/Button.vue";
import Value from "@/portals/shared/library/value/Value.vue";
import Spacer from "@/portals/shared/library/spacer/Spacer.vue";
import Label from "@/portals/shared/library/label/Label.vue";

import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import ProgressRow from '@/components/row/ProgressRow.vue';

export default {
  name: "confirm-remove-vehicle",
  components: {
    ProgressRow,
    Box, Row, Column,
    Spacer, TitleRow,
    Button, Value, Label,
    ErrorRow, SuccessRow,
  },
  props: {
    vehicle: Object,
  },
  data() {
    return {
      isRemoving: false,
      error: null,
      message: null,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,
    }
  },
  computed: {
    ...mapGetters([
      'domain',
      ]),
    title: function() {
      var msg = this.catelog.get(this.catelogKeys.VEHICLE.REMOVE_MESSAGE);
      var name = this.vehicle.name;
      return StringUtils.format(msg, [name]);
    }
  },
  watch: {
  },
  methods: {
    vehicleObj: function() {
      return this.domain.vehicles().findById(this.vehicle['@rid']);
    },
    confirm: function () {
      this.isRemoving = true;
      var event = this.domain.events().vehicles().remove(this.vehicle);
      event.send(this.listener);
    },
    listener: function(rcEvent) {
      this.isRemoving = false
      if (rcEvent.error()) {
        this.error = rcEvent.error();
      } else {
        this.done();
      }
    },
    done: function() {
      this.$emit("closeConfirmed");
    },
    reject: function () {
      this.$emit("closeRejected");
    },
  },
};
</script>