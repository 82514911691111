<template>
<Row :showBorder="showBorder">

    <Column :width="1">
      <Button v-on:click="details()" 
        :disabled="isSelected('details')"> 
        {{ catelog.get(catelogKeys.ACTIONS.DETAILS) }}
        </Button>
    </Column>
        
    <Column :width="4" />
        
    <Column :width="1">
      <Button v-on:click="inspections()" 
        :disabled="isSelected('inspections')"> 
        Inspections
      </Button>
    </Column>
    
    <Column :width="5" />
        
    <Column :width="1" v-if="!isAdminList">
      <Button v-if="vehicleData && vehicleData.deleted == 'false'" v-on:click="remove()" 
        :disabled="false"> 
        {{ catelog.get(catelogKeys.ACTIONS.REMOVE) }}
      </Button>
      <Button v-else v-on:click="restore()" 
        :disabled="false">
        {{ catelog.get(catelogKeys.ACTIONS.RESTORE) }}
      </Button>
    </Column>
    <Column :width="1" v-else />
    
    <Column :width="1" v-if="!isAdminList">
      <Button v-on:click="edit()" 
        :disabled="isSelected('edit')"> 
        {{ catelog.get(catelogKeys.ACTIONS.EDIT) }}
      </Button>
    </Column>
    <Column v-else :width="1" />
    
    <ConfirmRemoveDialog 
      v-if="confirming" 
      @closeRejected="closeRejected" 
      @closeConfirmed="closeConfirmed" 
      :vehicle="this.removingVehicle" 
      />
  
    <ConfirmRestoreDialog 
      v-if="confirmingRestore" 
      @closeRejected="closeRejected" 
      @closeConfirmed="closeConfirmed" 
      :vehicleData="this.restoringVehicle" 
      />
</Row>

</template>

<script>
import { mapGetters } from 'vuex';

import ConstUtils   from '@/utils/ConstUtils.js';

import Row from '@/portals/shared/library/box/Row.vue';
import Column from '@/portals/shared/library/box/Column.vue';

import Button   from '@/portals/shared/library/button/Button.vue';

import ConfirmRemoveDialog from '@/views/portal/vehicles/remove/ConfirmRemoveVehicle.vue';
import ConfirmRestoreDialog from '@/views/portal/vehicles/remove/ConfirmRestoreVehicle.vue';

import Catelog from "@/domain/session/CanadianEnglish.js";

export default {
  name: 'portal-vehicle-menu',
  components: {
    Row, Column, Button, 
    ConfirmRemoveDialog,
    ConfirmRestoreDialog,
  },
  props: {
    isAdmin:  { type: Boolean, default: false },
    isAdminList:  { type: Boolean, default: false },
    showBorder:  { type: Boolean, default: false },
    index:    { type: Number, default: 0 },
    vehicleData: { type: Object, default: null},
    selected: { type: String, default: "details" },
  },
  data() {
    return { 
      confirming: false,
      removingVehicle: null,
      
      confirmingRestore: false,
      restoringVehicle: null,
      
      catelog: new Catelog(),
      catelogKeys: Catelog.KEYS,

    };
  },
  computed: {
    ...mapGetters( [
                    'auth_client', 
                    'auth_connected', 
                    'domain',
                    ] ),
  },
  watch: {
    
  },
  mounted: function() {
    
  },
  methods: {
    restore: function() {
      this.confirmingRestore = true;
      this.restoringVehicle = this.vehicleData;
    },
    
    remove: function () {
      this.confirming = true;
      this.removingVehicle = this.vehicleData;
    },
    
    closeRejected: function () {
      this.removingVehicle = null;
      this.restoringVehicle = null;
      this.confirming = false;
      this.confirmingRestore = false;
    },
    
    closeConfirmed: function() {
      this.confirming = false;
      this.confirmingRestore = false;
      this.$emit("action", "refresh");
    },
   
    inspections: function () {
      var params = {
        id: this.vehicleData[ConstUtils.FIELDS.ID],
      }
      this.$router.push({
        name: ConstUtils.ROUTES.VEHICLE.INSPECTIONS,
        params: params,
      });
    },
    
    details: function () {
      var params = {
        id: this.vehicleData[ConstUtils.FIELDS.ID],
      }
      this.$router.push({
        name: ConstUtils.ROUTES.VEHICLE.DETAILS,
        params: params,
      });
    },

    edit: function () {
      var params = {
        id: this.vehicleData[ConstUtils.FIELDS.ID],
      }
      this.$router.push({
        name: ConstUtils.ROUTES.VEHICLE.EDIT_V2,
        params: params,
      })
    },
    
    isSelected: function(value) {
      return this.selected === value;
    }
  }
}


</script>